import Router from 'next/router';
import { urlQueryToSearchParams } from 'next/dist/shared/lib/router/utils/querystring';

export const buildStringUrl = (route) => new URL(route.as, window.location.origin).toString();

export const buildRoute = (href, params, queryParams, hashParams) => {
  const route = {
    link: href,
    href: {
      pathname: href,
      query: {},
    },
  };

  if (params) {
    route.link = Object.keys(params).reduce((as, key) => as.replace(`[${key}]`, params[key]), route.link);
    route.href.query = params;
  }

  if (queryParams) {
    const queryString = urlQueryToSearchParams(queryParams).toString();
    route.link = `${route.link}?${queryString}`;
    route.href.query = { ...route.href.query, ...queryParams };
  }

  if (!queryParams && !params) {
    route.href = href;
  }

  if (hashParams) {
    const hashString = urlQueryToSearchParams(hashParams).toString();
    route.link = `${route.link}#${hashString}`;
    if (!queryParams && !params) {
      route.href = `${route.link}#${hashString}`;
    } else route.href.hash = hashString;
  }

  return route;
};

export const routes = {
  home: (queryParams) => buildRoute('/', null, queryParams),
  support: () => buildRoute('/support'),
  organizations: () => buildRoute('/organizations'),
  notFound: () => buildRoute('/404'),
  detroitJobs: () => buildRoute('/detroitjobs'),
  userTickets: () => buildRoute('/user/orders'),
  userTicket: (orderId) => buildRoute('/user/orders/[orderId]', { orderId }),
  userProfile: () => buildRoute('/user/profile'),
  userInterests: () => buildRoute('/user/interests'),
  userFavoriteEvents: () => buildRoute('/user/favorite-events'),
  userFavoriteUpcomingEvents: () => buildRoute('/user/favorite-events/upcoming'),
  userFavoritePastEvents: () => buildRoute('/user/favorite-events/past'),
  userIntegrations: () => buildRoute('/user/profile/integrations'),
  userSettings: () => buildRoute('/user/profile/settings'),
  userPreferences: () => buildRoute('/user/profile/preferences'),
  userCommunicationPreferences: () => buildRoute('/user/profile/communication-preferences'),
  userPaymentMethods: () => buildRoute('/user/payment-methods'),
  privacyPolicy: () => buildRoute('/privacy'),
  termsOfUse: () => buildRoute('/terms'),
  zoomDocs: () => buildRoute('/zoomDocumentation'),
  adminManageAdmins: () => buildRoute('/admin/manage/admins'),
  adminManagePlatformUsers: () => buildRoute('/admin/manage/users'),
  adminManageOrganizations: () => buildRoute('/admin/manage/organizations'),
  adminManagePlanningCalendar: () => buildRoute('/admin/manage/planning-calendar'),
  adminManagePlanningCalendarSettings: () => buildRoute('/admin/manage/planning-calendar/settings'),
  adminManageAdmin: (userId) => buildRoute('/admin/manage/[userId]', { userId }),
  event: (eventId) => buildRoute('/events/[eventId]', { eventId }),
  eventEmailSubscribe: (eventId) => buildRoute('/events/[eventId]/email-subscribe', { eventId }),
  events: (queryParams) => buildRoute('/events', {}, queryParams),
  eventTickets: (eventId, queryParams) => buildRoute('/events/[eventId]/tickets', { eventId }, queryParams),
  eventRegistration: (eventId, queryParams) => buildRoute('/events/[eventId]/registration', { eventId }, queryParams),
  eventOrder: (eventId, queryParams) => buildRoute('/events/[eventId]/order', { eventId }, queryParams),
  eventOrderSendTo: (eventId, queryParams) => buildRoute('/events/[eventId]/order/send-to', { eventId }, queryParams),
  eventOrderSummary: (eventId, queryParams) => buildRoute('/events/[eventId]/order/summary', { eventId }, queryParams),
  eventOrderPaymentMethod: (eventId, queryParams) =>
    buildRoute('/events/[eventId]/order/payment-method', { eventId }, queryParams),
  eventIcs: (eventId) => buildRoute('/events/[eventId]/ics', { eventId }),
  organizationProfile: (organizationId) => buildRoute('/organizations/[organizationId]', { organizationId }),
  upcomingEvents: (organizationId) => buildRoute('/organizations/[organizationId]/upcoming-events', { organizationId }),
  pastEvents: (organizationId) => buildRoute('/organizations/[organizationId]/past-events', { organizationId }),
  promoOct7memorial: (channelSlug) => buildRoute('/c/[channelSlug]/oct7memorial', { channelSlug }),
  promoIndex: (marketSlug) => buildRoute('/e/[marketSlug]', { marketSlug }),
  israel75Events: (marketSlug) => buildRoute('/e/[marketSlug]/israel75', { marketSlug }),
  familyEvents: (marketSlug) => buildRoute('/e/[marketSlug]/families', { marketSlug }),
  summerEvents: (marketSlug) => buildRoute('/e/[marketSlug]/summer', { marketSlug }),
  fallEvents: (marketSlug) => buildRoute('/e/[marketSlug]/fall', { marketSlug }),
  teenEvents: (marketSlug) => buildRoute('/e/[marketSlug]/teens', { marketSlug }),
  jewishHolidaysEvents: (marketSlug) => buildRoute('/e/[marketSlug]/jewishholidays', { marketSlug }),
  volunteering: (marketSlug) => buildRoute('/e/[marketSlug]/volunteering', { marketSlug }),
  youngAdultEvents: (marketSlug) => buildRoute('/e/[marketSlug]/young-adults', { marketSlug }),
  campEvents: (marketSlug) => buildRoute('/e/[marketSlug]/camp', { marketSlug }),
  inPersonEvents: (marketSlug) => buildRoute('/e/[marketSlug]/in-person', { marketSlug }),
  jewishLearningEvents: (marketSlug) => buildRoute('/e/[marketSlug]/jewish-learning', { marketSlug }),
  adultLearningEvents: (marketSlug) => buildRoute('/e/[marketSlug]/adult-learning-and-experiences', { marketSlug }),
  daysOfMemoryEvents: (marketSlug) => buildRoute('/e/[marketSlug]/days-of-memory-and-meaning', { marketSlug }),
  file: (fileType, fileKey, queryParams) =>
    buildRoute('/file/[fileType]/[fileKey]', { fileType, fileKey }, queryParams),
  adminHome: () => buildRoute('/admin'),
  adminAllEvents: () => buildRoute('/admin/all-events'),
  adminMarketEvents: (marketSlug) => buildRoute('/admin/[marketSlug]/all-events', { marketSlug }),
  adminOrganizationEvents: (organizationId, queryParams, hashParams) =>
    buildRoute('/admin/organizations/[organizationId]/events', { organizationId }, queryParams, hashParams),
  adminOrganizationArchivedEvents: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/events/archived', { organizationId }),
  adminOrganizationProfile: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/profile', { organizationId }),
  adminOrganizationProfileSettings: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/profile/settings', { organizationId }),
  adminOrganizationProfileQuestionsBank: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/profile/questions-bank', { organizationId }),
  adminOrganizationDashboard: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/dashboard', { organizationId }),
  adminOrganizationReports: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/reports', { organizationId }),
  adminOrganizationAdmins: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/admins', { organizationId }),
  adminOrganizationAdmin: (organizationId, userId) =>
    buildRoute('/admin/organizations/[organizationId]/admins/[userId]', { organizationId, userId }),
  adminOrganizationReport: (organizationId, reportId) =>
    buildRoute('/admin/organizations/[organizationId]/reports/[reportId]', { organizationId, reportId }),
  adminOrganizationBilling: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/billing', { organizationId }),
  adminOrganizationBillingSpreedy: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/billing-spreedly', { organizationId }),
  adminOrganizationJobs: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/jobs', { organizationId }),
  adminCreateEvent: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/events/create', { organizationId }),
  adminEditEvent: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]', { organizationId, eventId }),
  adminEditEventTranslations: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/translations', {
      organizationId,
      eventId,
    }),
  adminEditEventTickets: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/tickets', { organizationId, eventId }),
  adminEditEventQuestions: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/questions', { organizationId, eventId }),
  adminEventSettings: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/settings', { organizationId, eventId }),
  adminPublishEvent: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/publish', { organizationId, eventId }),
  adminPreviewEvent: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/preview', { organizationId, eventId }),
  adminPreviewOrganization: (organizationId) =>
    buildRoute('/admin/organizations/[organizationId]/preview', { organizationId }),
  zoomConfirmation: () => buildRoute('/zoom/oauth/confirmation'),
  stripeConfirmation: () => buildRoute('/stripe/oauth/confirmation'),
  adminEventDashboard: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/dashboard', {
      organizationId,
      eventId,
    }),
  adminAttendees: (organizationId, eventId, queryParams) =>
    buildRoute(
      '/admin/organizations/[organizationId]/events/[eventId]/attendees',
      {
        organizationId,
        eventId,
      },
      queryParams,
    ),
  adminAttendeesCheckedIn: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/attendees/checked-in', {
      organizationId,
      eventId,
    }),
  adminAttendeesRegistered: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/attendees/registered', {
      organizationId,
      eventId,
    }),
  adminOrders: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/orders', {
      organizationId,
      eventId,
    }),
  adminPromoCodes: (organizationId, eventId, hashParams) =>
    buildRoute(
      '/admin/organizations/[organizationId]/events/[eventId]/promoCodes',
      {
        organizationId,
        eventId,
      },
      {},
      hashParams,
    ),
  adminPromoCode: (organizationId, eventId, promoCodeId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/promoCodes/[promoCodeId]', {
      organizationId,
      eventId,
      promoCodeId,
    }),
  adminOrdersPending: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/orders/pending', {
      organizationId,
      eventId,
    }),
  adminOrdersRefunded: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/orders/refunded', {
      organizationId,
      eventId,
    }),
  adminOrdersCanceled: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/orders/canceled', {
      organizationId,
      eventId,
    }),
  adminOrdersNotYetPaid: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/orders/not-yet-paid', {
      organizationId,
      eventId,
    }),
  adminOrdersPaidOffline: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/orders/paid-offline', {
      organizationId,
      eventId,
    }),
  adminEventMessages: (organizationId, eventId) =>
    buildRoute('/admin/organizations/[organizationId]/events/[eventId]/messages', {
      organizationId,
      eventId,
    }),
};

export const redirectTo = async (res, { href, link }, pageProps = {}) => {
  if (res) {
    res.writeHead(303, { Location: link });
    res.end();
  } else {
    await Router.replace(link);
  }

  return pageProps;
};
