import { OrderStatus, PromoCodeDisplay } from 'api/general/models';
import { createAction } from 'store/utils';
import { EntityType } from 'store/entities/types';
import { actionsFactories } from 'store/features/utils';
import {
  AnswerType,
  CartStateType,
  CheckAttendeeParamsType,
  CheckPromoCodeParamsType,
  DonationStateType,
  HistoryStateType,
  ItemStringType,
  OrderItemStringType,
  SpreedlyCheckPurchaseStatusParamsType,
  StepType,
} from './types';

export const initCart = createAction('INIT_CART', (state: Partial<CartStateType>) => ({
  payload: {
    state,
  },
}));

export const initRegistration = createAction('INIT_REGISTRATION', (state: HistoryStateType<StepType>) => ({
  payload: {
    state,
  },
}));

export const addOrderItem = createAction(
  'ADD_ORDER_ITEM',
  (id: number, itemType: OrderItemStringType, count: number, hasQuestions: boolean, order: number) => ({
    payload: {
      id,
      itemType,
      count,
      hasQuestions,
      order,
    },
  }),
);

export const removeOrderItem = createAction('REMOVE_ORDER_ITEM', (id: number, itemType: OrderItemStringType) => ({
  payload: {
    id,
    itemType,
  },
}));

export const setSendTo = createAction('SET_SEND_TO', (sendTo: string) => ({
  payload: {
    sendTo,
  },
}));

export const setConvertToHardUser = createAction('SET_CONVERT_TO_HARD_USER', (convertToHardUser: boolean) => ({
  payload: {
    convertToHardUser,
  },
}));

export const setAnswer = createAction(
  'SET_ANSWER',
  (answer: AnswerType, itemType: ItemStringType, itemIndex?: number) => ({
    payload: {
      answer,
      itemType,
      itemIndex,
    },
  }),
);

export const setForBuyer = createAction(
  'SET_FOR_BUYER',
  (forBuyer: boolean, itemType: ItemStringType, itemIndex?: number) => ({
    payload: {
      forBuyer,
      itemType,
      itemIndex,
    },
  }),
);

export const setSetupFutureUsage = createAction('SET_SETUP_FUTURE_USAGE', (setupFutureUsage: boolean) => ({
  payload: {
    setupFutureUsage,
  },
}));

export const setPaidOffline = createAction('SET_PAID_OFFLINE', (paidOffline: boolean) => ({
  payload: {
    paidOffline,
  },
}));

export const removeFutureStep = createAction('REMOVE_FUTURE_STEP', (itemType: ItemStringType, itemIndex?: number) => ({
  payload: {
    itemType,
    itemIndex,
  },
}));

export const selectStripePaymentMethod = createAction('SELECT_STRIPE_PAYMENT_METHOD', (id: string) => ({
  payload: {
    id,
  },
}));

export const selectSpreedlyPaymentMethod = createAction('SELECT_SPREEDLY_PAYMENT_METHOD', (id: string) => ({
  payload: {
    id,
  },
}));

export const changeDonation = createAction('CHANGE_DONATION', (value: DonationStateType) => ({
  payload: {
    value,
  },
}));

export const submitAnswer = createAction('SUBMIT_ANSWER', (answer: AnswerType) => ({
  payload: {
    answer,
  },
}));

export const clearAnswer = createAction('CLEAR_ANSWER', (itemType: ItemStringType, itemIndex?: number) => ({
  payload: {
    itemType,
    itemIndex,
  },
}));

export const submitOrder = createAction('SUBMIT_ORDER');

export const generateRegistrationSteps = createAction('GENERATE_REGISTRATION_STEPS');

export const prevQuestion = createAction('PREV_QUESTION');

export const nextQuestion = createAction('NEXT_QUESTION');

export const resetCreatingOrder = createAction('RESET_CREATING_ORDER');

export const resetCart = createAction('RESET_CART');

export const resetRegistration = createAction('RESET_REGISTRATION');

export const resetPayment = createAction('RESET_PAYMENT');

export const resetDonation = createAction('RESET_DONATION');

export const createOrder = actionsFactories.createSingleFeatureActionsFactory<never, EntityType<OrderStatus>>()(
  'CREATE_ORDER_REQUEST',
  'CREATE_ORDER_SUCCESS',
  'CREATE_ORDER_FAILURE',
  'CREATE_ORDER_RESET',
);

export const payOrder = actionsFactories.createSingleFeatureActionsFactory<never, EntityType<OrderStatus>>()(
  'PAY_ORDER_REQUEST',
  'PAY_ORDER_SUCCESS',
  'PAY_ORDER_FAILURE',
  'PAY_ORDER_RESET',
);

export const spreedlyPayOrder = actionsFactories.createSingleFeatureActionsFactory<never, EntityType<OrderStatus>>()(
  'SPREEDLY_PAY_ORDER_REQUEST',
  'SPREEDLY_PAY_ORDER_SUCCESS',
  'SPREEDLY_PAY_ORDER_FAILURE',
  'SPREEDLY_PAY_ORDER_RESET',
);

export const setupOfflinePaymentStripe = actionsFactories.createSingleFeatureActionsFactory<
  never,
  EntityType<OrderStatus>
>()(
  'SETUP_OFFLINE_PAYMENT_STRIPE_REQUEST',
  'SETUP_OFFLINE_PAYMENT_STRIPE_SUCCESS',
  'SETUP_OFFLINE_PAYMENT_STRIPE_FAILURE',
  'SETUP_OFFLINE_PAYMENT_STRIPE_RESET',
);

export const setupOfflinePaymentSpreedly = actionsFactories.createSingleFeatureActionsFactory<
  never,
  EntityType<OrderStatus>
>()(
  'SETUP_OFFLINE_PAYMENT_SPREEDLY_REQUEST',
  'SETUP_OFFLINE_PAYMENT_SPREEDLY_SUCCESS',
  'SETUP_OFFLINE_PAYMENT_SPREEDLY_FAILURE',
  'SETUP_OFFLINE_PAYMENT_SPREEDLY_RESET',
);

export const confirmCardPayment = actionsFactories.createSingleFeatureActionsFactory<never>()(
  'CONFIRM_CARD_PAYMENT_REQUEST',
  'CONFIRM_CARD_PAYMENT_SUCCESS',
  'CONFIRM_CARD_PAYMENT_FAILURE',
  'CONFIRM_CARD_PAYMENT_RESET',
);

export const spreedlyCheckPurchaseStatus =
  actionsFactories.createSingleFeatureActionsFactory<SpreedlyCheckPurchaseStatusParamsType>()(
    'SPREEDLY_CHECK_PURCHASE_STATUS_REQUEST',
    'SPREEDLY_CHECK_PURCHASE_STATUS_SUCCESS',
    'SPREEDLY_CHECK_PURCHASE_STATUS_FAILURE',
    'SPREEDLY_CHECK_PURCHASE_STATUS_RESET',
  );

export const checkPromoCode = actionsFactories.createSingleFeatureActionsFactory<
  CheckPromoCodeParamsType,
  EntityType<PromoCodeDisplay>
>()('CHECK_PROMO_CODE_REQUEST', 'CHECK_PROMO_CODE_SUCCESS', 'CHECK_PROMO_CODE_FAILURE', 'CHECK_PROMO_CODE_RESET');

export const checkAttendee = actionsFactories.createSingleFeatureActionsFactory<CheckAttendeeParamsType, any>()(
  'CHECK_ATTENDEE_REQUEST',
  'CHECK_ATTENDEE_SUCCESS',
  'CHECK_ATTENDEE_FAILURE',
  'CHECK_ATTENDEE_RESET',
);
